class Anchors {
    items = [];
    public hash: any;
    nav = $('nav');

    constructor() {
        this.parse();
        $(window).on('hashchange', this.hashchange.bind(this));
    }

    public parse = () => {
        $('.js-anchor').each((index, item) => {
            let anchorItem = $(item);
            if (!anchorItem.data('anchor')) {
                let anchor = new Anchor(anchorItem); // Create a new Anchor instance
                anchorItem.data('anchor', anchor);  // Store the instance for reference
                this.items.push(anchor);            // Add to the items list
            }
        });
        this.hashchange(); // Handle hash change after parsing
    };

    public hashchange = (event?) => {
        const hash = window.location.hash.replace('#', '');
        this.hash = hash;

        if (hash) {
            for (let i = 0; i < this.items.length; i++) {
                let item = this.items[i];
                if (item.id === hash) {
                    $(item.element.addClass('is-active'));
                    $('body, html').stop(true).animate({
                        'scrollTop': item.target.offset().top - this.nav.outerHeight()
                    });
                } else {
                    $(item.element.removeClass('is-active'));
                }
            }
        }
    };
}

class Anchor {
    element: any;
    id: any;
    target: any;

    constructor(element) {
        this.element = element;
        if (!this.element.length) return;

        this.element.on('click', this.click.bind(this));

        this.id = this.element.attr('href').split('#').pop();

        this.target = $('#' + this.id);
        this.target.attr('id', 'js-' + this.id); // Add prefix to avoid ID conflicts
    }

    public click = (event) => {
        event.preventDefault(); // Prevent default anchor click behavior
        if (this.id === window.location.hash.replace('#', '')) {
            console.log('Anchor clicked:', this.id);
        }
        window.location.hash = this.id; // Update the hash in the URL
    };
}

let anchors = new Anchors();
