class Menu {
    menu: any;
    navpos: any;
    constructor() {
        $('.c-nav-toggle').on('click', this.toggle.bind(this));
        $('main').on('click', this.destroy.bind(this));
        $(window).resize(this.destroy.bind(this));
        $(window).resize(this.height.bind(this));
        this.height();
        //Scroll function
        // vind positie nav
        // vind positie top scherm
        // als positie top scherm > nav is.
        // nav position fixed geven
        if ($(window).width() >= 769) {
      	  this.navpos = $('nav').offset().top as number;
          $(window).on("scroll", this.navpostion.bind(this));
        }
    }

    navpostion(navpos){
      const nav = $('nav');

      const scrollTop = $(window).scrollTop() as number;
      if(scrollTop >= this.navpos) {
        if(!nav.hasClass("is-sticky")) {
          nav.addClass("is-sticky");
        }else {
          return;
        }
      }else {
          nav.removeClass("is-sticky");
      }

    }
    toggle(event){
        event.preventDefault();
        $('body').toggleClass('is-activeNav');
    }
    destroy() {
        if ($('.is-activeNav').length) {
            $('body').removeClass('is-activeNav');
        }
    }

    height() {
        if ($(window).width() >= 600) {
            this.menu = $('.c-nav').innerHeight();
           $('body').css({"padding-top": `${this.menu - 0}px`});
        } else if ($(window).width() <= 600) {
            $('body').css({"padding-top": `0px`});
        }
    }
}
let menu = new Menu();
